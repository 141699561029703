module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Солид Лизинг для клиентов","short_name":"Солид–Лизинг","start_url":"/","background_color":"#64477e","theme_color":"#64477e","display":"minimal-ui","icon":"src/assets/images/solid-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dc1fad3bedff096abdae08c7e7eb8044"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
